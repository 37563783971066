import React, { useReducer, useEffect, useState } from 'react'
import '../../styles/pages/boerse.scss';

import { useNavigate, useParams, Link } from "react-router-dom";

import Header from '../../containers/header/Header';
import Footer from '../../containers/footer/Footer';


import FilterGroup from '../../containers/filter-group/FilterGroup';

import config from '../../containers/filter-group/trainerfinder-config';
import { FilterContext, useFilter } from '../../containers/filter-group/context/FilterContext'; // Import your FilterContext
import PreviewItems from '../../containers/filter-group/PreviewItems';

import client from '../../client';
import { readItems } from '@directus/sdk';

import { useTranslation } from 'react-i18next';

import { useLocation } from 'react-router-dom';
import Item from '../boerse/TrainerfinderItemSmall';


export default function Boerse() {

    const navigate = useNavigate();

    const { setData, setFilterItems, visibleData } = useFilter();

    const { t } = useTranslation(['erfassen-seite', 'config']);
    let location = useLocation();

    const { id } = useParams();


    useEffect(() => {
        if (id) {
            navigate(`/de/modal/${id}`, { state: { background: location } });
        }
    }, [id])


    const getFilterItems = (data, key) => {
        if (key === 'organisationsform') {
            data.map((item) => {
                if (item[key] === null) {
                    item[key] = t('Leiterperson');
                }
            });
        }
        return data
            .filter((item) => item[key] !== undefined && item[key] !== null)
            .filter((value, index, self) =>
                index === self.findIndex((t) => (
                    t[key] === value[key]
                ))
            )
            .map((item) => (
                { 'name': item[key], 'slug': item[key] }
            ));
    }



    const getFilterItemsFromArray = (data, key) => {
        const flattenedItems = data.reduce((acc, item) => {
            if (item[key] !== undefined && item[key] !== null) {
                if (item[key] === '') {
                    return acc;
                }
                if (!item[key].includes('[')) {
                    acc.push({ 'name': item[key], 'slug': item[key] });
                    return acc;
                }

                if (item[key].includes('[')) {
                    const arr = JSON.parse(item[key]);

                    arr.forEach(subItem => {
                        acc.push({ 'name': subItem, 'slug': subItem });
                    });
                } else {
                    acc.push({ 'name': item[key], 'slug': item[key] });
                }
            }
            return acc;
        }, []);

        return flattenedItems.filter((value, index, self) =>
            index === self.findIndex((t) => (
                t['slug'] === value['slug']
            ))
        );;
    }

    console.log('test')




    useEffect(() => {

        const fetchData = async () => {


            /*
            const data = await client.request(readItems('angebote', { sort: ['-date_created'] }));
 
 
            let angeboteData = data.data.map((item) => ({
                ...item,
                ...JSON.parse(item.formdata)
            }));
 
            delete angeboteData.formdata;
 
*/

            const postsData = await client.request(readItems('trainer_items', { sort: ['-date_updated'], fields: '*, image.*', query: { status: { _neq: "deleted" } } }));
            const filteredPostData = postsData.filter((item) => item.status !== 'deleted');


            const tmpData = [
                ...filteredPostData,
            ]

            setData(tmpData);
            setFilterItems('disziplinen', getFilterItemsFromArray(tmpData, 'disziplinen'));
            setFilterItems('wohnkanton', getFilterItems(tmpData, 'wohnkanton'));
            setFilterItems('erfahrung', getFilterItemsFromArray(tmpData, 'erfahrung'));
            setFilterItems('angebot', getFilterItemsFromArray(tmpData, 'angebot'));

            setFilterItems('js_ausbildung', getFilterItemsFromArray(tmpData, 'js_ausbildung'));
            setFilterItems('scg_ausbildung', getFilterItemsFromArray(tmpData, 'scg_ausbildung'));
            setFilterItems('availability', getFilterItemsFromArray(tmpData, 'availability'));
            setFilterItems('organisationsform', getFilterItems(tmpData, 'organisationsform'));



        }

        fetchData()
            .catch(console.error);
    }, [])

    const filterCategories = config['item'].filterCategories.map(category => ({
        ...category,
        label: t(category.labelKey),
    }));


    return (<>
        <Header />

        <div className='add-new'>

            <div>
                <h1>{t('slogan')}</h1>
                <h2>{t('Übersicht')}</h2>

                <p>{t('erfassen_intro')}</p>
                <button className="button" onClick={() => navigate('neuer-eintrag')}>{t('Neuer Eintrag')}</button>
            </div>

            <FilterGroup filterCategories={filterCategories} searchPlaceholder="Suche" />
            <PreviewItems data={visibleData} postType={'item'} TeaseComponent={Item} />

        </div >
        <Footer />
    </>
    )
}
